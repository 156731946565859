// Import dependencies
import React from "react"
import Clock from "react-live-clock"

// Import components
import { Box, TitleText, ParagraphText } from "../styled/UILibrary"

// Import styles
import "./factorycard.css"

const FactoryCard = ({ image, title }) => (
  <Box className="fc__container" padding="20px" rounded>
    <div>
      <div className="fc__image-container">
        {image}
        <div className="fc__city-time-container">
          <ParagraphText minSize={16} maxSize={18} white shadow>
            <Clock format={"HH:mm:ss"} ticking={true} timezone={"Egypt"} />
          </ParagraphText>
          <TitleText
            minSize={28}
            maxSize={30}
            white
            nospacing
            nolineheight
            shadow
          >
            {title}
          </TitleText>
        </div>
      </div>

      {/* <div className='fc__row'>
                <TitleText minSize={14} maxSize={16} gray nospacing>
                    Phone
                </TitleText>
                <TitleText minSize={20} maxSize={22} nospacing>
                    (+202) 27351005
                </TitleText>
            </div> */}
      <div className="fc__row">
        <TitleText minSize={14} maxSize={16} gray nospacing>
          Email
        </TitleText>
        <TitleText minSize={20} maxSize={22} nospacing blue>
          local@spiroplastic.com
        </TitleText>
      </div>
      <div className="fc__row">
        <TitleText minSize={14} maxSize={16} gray nospacing>
          Address
        </TitleText>
        <ParagraphText minSize={16} maxSize={18} nospacing blue>
        <a href="https://www.google.com/maps/place/4+Omarat+Aliamni,+Omar+Al+Khayam,+Zamalek,+Cairo+Governorate,+Egypt/data=!4m2!3m1!1s0x145840e753f81e9b:0x6cda423b8799e658?sa=X&ved=2ahUKEwjVl8nKk9zpAhUPiFwKHamOBW0Q8gEwAHoECAsQAQ"
          className="textsection__link"
          target="_blank"
          rel="noopener noreferrer"
          aria-label="Google Maps"
        >
          4 Omarat Aliamni St., Zamalek, Cairo</a>
        </ParagraphText>
      </div>
      <div className="fc__row">
        <TitleText minSize={14} maxSize={16} gray nospacing>
          Telephone
        </TitleText>
        <TitleText minSize={16} maxSize={18} nospacing underligned>
          +20 225 791 043
        </TitleText>
      </div>

      <div className="fc__styler" />
    </div>
  </Box>
)

export default FactoryCard
