// Import dependencies
import React from "react"

// Import components
import { Box, TitleText, ParagraphText } from "../styled/UILibrary"

// Import styles
import "./contactcard.css"

export default function ContactCard(props) {
    return <Box className="fc__container" padding="20px" rounded>
    <div>
      {/* <div className='fc__row'>
                <TitleText minSize={14} maxSize={16} gray nospacing>
                    Phone
                </TitleText>
                <TitleText minSize={20} maxSize={22} nospacing>
                    (+202) 27351005
                </TitleText>
            </div> */}
      <div className="fc__row">
        <TitleText minSize={14} maxSize={16} gray nospacing>
          Branch Name
        </TitleText>
        <TitleText 
        minSize={20} 
        maxSize={22} 
        nospacing 
        black
        >
          {props.BranchName}
        </TitleText>
      </div>
      <div className="fc__row">
        <TitleText minSize={14} maxSize={16} gray nospacing>
          Branch Manager
        </TitleText>
        <TitleText 
        minSize={16} 
        maxSize={18} 
        nospacing 
        black
        >
          {props.BranchManager}
        </TitleText>
      </div>
      <div className="fc__row">
        <TitleText minSize={14} maxSize={16} gray nospacing>
          Address
        </TitleText>
        <TitleText 
        minSize={16} 
        maxSize={18} 
        nospacing 
        underligned
        >
          {props.Address}
        </TitleText>
      </div>
      <div className="fc__row">
        <TitleText minSize={14} maxSize={16} gray nospacing>
          Phone
        </TitleText>
        <ParagraphText 
        minSize={16} 
        maxSize={18} 
        nospacing 
        underligned
        >
          {props.Phone}
        </ParagraphText>
      </div>

      {/* <div className="fc__styler" /> */}
    </div>
  </Box>
}

