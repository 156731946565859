// Import dependencies
import React from 'react'

// Bootstrap components
import Col from 'react-bootstrap/Col'
import Form from 'react-bootstrap/Form'
import Button from 'react-bootstrap/Button'

// Import styles
import './contactform.css'
import 'bootstrap/dist/css/bootstrap.css'


export default class ContactForm extends React.Component {
    constructor(...args) {
        super(...args);
        this.state = { validated: false };
    }

    handleSubmit(event) {
        const form = event.currentTarget;
        if (form.checkValidity() === false) {
            event.preventDefault();
            event.stopPropagation();
        }
        this.setState({ validated: true });
    }

    render() {
        const { validated } = this.state;
        return (
            <Form
                noValidate
                data-netlify="true"
                name='Spiroplastic Contact Form'
                method="post"
                data-netlify-honeypot="bot-field"
                validated={validated}
                onSubmit={e => this.handleSubmit(e)}
            >
                <input type="hidden" name="form-name" value="Spiroplastic Contact Form" />
                <Form.Row>
                    <Form.Group as={Col} md="6" sm='12' lg='3' controlId="validationCustom01" className='contactform__row'>
                        <Form.Control
                            required
                            type="text"
                            placeholder="Full Name"
                            size='lg'
                            className='cf__input'
                            name='fullName'
                        />
                        <Form.Control.Feedback>Looks good!</Form.Control.Feedback>
                    </Form.Group>
                    <Form.Group as={Col} md="6" sm='12' lg='3' controlId="validationCustom02" className='contactform__row'>
                        <Form.Control
                            required
                            type="tel"
                            placeholder="Phone"
                            size='lg'
                            className='cf__input'
                            name='phone'
                        />
                        <Form.Control.Feedback>Looks good!</Form.Control.Feedback>
                    </Form.Group>
                    <Form.Group as={Col} md="6" sm='12' lg='3' controlId="validationCustom02" className='contactform__row'>
                        <Form.Control
                            required
                            type="email"
                            placeholder="E-Mail"
                            size='lg'
                            className='cf__input'
                            name='email'
                        />
                        <Form.Control.Feedback>Looks good!</Form.Control.Feedback>
                    </Form.Group>
                    <Form.Group as={Col} md="6" sm='12' lg='3' controlId="validationCustom02" className='contactform__row'>
                        <Form.Control
                            required
                            type="text"
                            placeholder="Company"
                            size='lg'
                            className='cf__input'
                            name='company'
                        />
                        <Form.Control.Feedback>Looks good!</Form.Control.Feedback>
                    </Form.Group>
                </Form.Row>
                <Form.Row>
                    <Form.Group as={Col} xs="12" controlId="exampleForm.ControlTextarea1">
                        <Form.Control
                            required
                            type="text"
                            as="textarea"
                            rows="8"
                            placeholder="Your message"
                            size='lg'
                            className='cf__input-large'
                            name='message'
                        />
                    </Form.Group>
                </Form.Row>
                <div className='cf__button-container'>
                    <Form.Group>
                        <Form.Check
                            required
                            label="I consent to process my personal data"
                            feedback="You must agree before submitting."
                            size='lg'
                            className='cf__check'
                        />
                    </Form.Group>
                    <Button
                        type="submit"
                        size='lg'
                        className='cf__button'
                    >
                        SEND MESSAGE
                    </Button>
                </div>
            </Form>
        );
    }
}
