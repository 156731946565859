// Import dependencies
import React from 'react'

// Import components
import { SubTitleText, TitleText } from '../styled/UILibrary'

// Import styles
import './textsection.css'

const TitleSection = ({ smallTitle, title, green, fullWidth }) => (
    <div className='textsection__container'>
        <SubTitleText minSize={14} maxSize={16} green={green}>
            { smallTitle }
        </SubTitleText>
        <TitleText minSize={22} maxSize={40} style={fullWidth ? { marginBottom: 40 } : { width: '70%', marginBottom: 40 }}>
            { title }
        </TitleText>
    </div>
)

export default TitleSection
