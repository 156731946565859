// Import dependencies
import React from "react"
import GoogleMapReact from "google-map-react"

// Import styles
import "./googlemaps.css"

// Map styles
const mapStyle = {
  gestureHandling: "cooperative",
  mapTypeControl: false,
  streetViewControl: false,
  panControl: false,
  styles: [
    {
      featureType: "water",
      elementType: "geometry",
      stylers: [
        {
          color: "#e9e9e9",
        },
        {
          lightness: 17,
        },
      ],
    },
    {
      featureType: "landscape",
      elementType: "geometry",
      stylers: [
        {
          color: "#f5f5f5",
        },
        {
          lightness: 20,
        },
      ],
    },
    {
      featureType: "road.highway",
      elementType: "geometry.fill",
      stylers: [
        {
          color: "#ffffff",
        },
        {
          lightness: 17,
        },
      ],
    },
    {
      featureType: "road.highway",
      elementType: "geometry.stroke",
      stylers: [
        {
          color: "#ffffff",
        },
        {
          lightness: 29,
        },
        {
          weight: 0.2,
        },
      ],
    },
    {
      featureType: "road.arterial",
      elementType: "geometry",
      stylers: [
        {
          color: "#ffffff",
        },
        {
          lightness: 18,
        },
      ],
    },
    {
      featureType: "road.local",
      elementType: "geometry",
      stylers: [
        {
          color: "#ffffff",
        },
        {
          lightness: 16,
        },
      ],
    },
    {
      featureType: "poi",
      elementType: "geometry",
      stylers: [
        {
          color: "#f5f5f5",
        },
        {
          lightness: 21,
        },
      ],
    },
    {
      featureType: "poi.park",
      elementType: "geometry",
      stylers: [
        {
          color: "#dedede",
        },
        {
          lightness: 21,
        },
      ],
    },
    {
      elementType: "labels.text.stroke",
      stylers: [
        {
          visibility: "on",
        },
        {
          color: "#ffffff",
        },
        {
          lightness: 16,
        },
      ],
    },
    {
      elementType: "labels.text.fill",
      stylers: [
        {
          saturation: 36,
        },
        {
          color: "#333333",
        },
        {
          lightness: 40,
        },
      ],
    },
    {
      elementType: "labels.icon",
      stylers: [
        {
          visibility: "off",
        },
      ],
    },
    {
      featureType: "transit",
      elementType: "geometry",
      stylers: [
        {
          color: "#f2f2f2",
        },
        {
          lightness: 19,
        },
      ],
    },
    {
      featureType: "administrative",
      elementType: "geometry.fill",
      stylers: [
        {
          color: "#fefefe",
        },
        {
          lightness: 20,
        },
      ],
    },
    {
      featureType: "administrative",
      elementType: "geometry.stroke",
      stylers: [
        {
          color: "#fefefe",
        },
        {
          lightness: 17,
        },
        {
          weight: 1.2,
        },
      ],
    },
  ],
}

// Branch locations
const branchLocations = [
  {    
    lat: 30.08858100661741,
    lng: 31.303799000000026,
    location: "15 Mansheya El-Bakry, Heliopolis, Cairo Governorate",
    url: "https://www.google.com/maps/place/15+Mansheya+El-Bakry,+Heliopolis,+Cairo+Governorate,+Egypt/@30.0883344,31.3030846,19z/data=!4m5!3m4!1s0x14583fcdf940cdad:0x3f36f9f0363978c6!8m2!3d30.0881112!4d31.3031137",
  },
  {
    lat: 30.086024002534305,
    lng: 31.223788000000013,
    location: "34 , Elwehda St.",
    url: "https://www.google.com/maps/place/15+Mansheya+El-Bakry,+Heliopolis,+Cairo+Governorate,+Egypt/@30.0883344,31.3030846,19z/data=!4m5!3m4!1s0x14583fcdf940cdad:0x3f36f9f0363978c6!8m2!3d30.0881112!4d31.3031137",
  },
  {
    lat: 30.040868002529724,
    lng: 31.245541000000003,
    location: "19 , Elsheikh soliman from Elsheikh Rehan st",
    url: "https://www.google.com/maps/place/15+Mansheya+El-Bakry,+Heliopolis,+Cairo+Governorate,+Egypt/@30.0883344,31.3030846,19z/data=!4m5!3m4!1s0x14583fcdf940cdad:0x3f36f9f0363978c6!8m2!3d30.0881112!4d31.3031137",
  },
  {
    lat: 30.01088600165206,
    lng: 31.205787999999984,
    location: "467 ELHARAM ST., BESIDE LEDO HOTEL ",
    url: "https://www.google.com/maps/place/15+Mansheya+El-Bakry,+Heliopolis,+Cairo+Governorate,+Egypt/@30.0883344,31.3030846,19z/data=!4m5!3m4!1s0x14583fcdf940cdad:0x3f36f9f0363978c6!8m2!3d30.0881112!4d31.3031137",
  },
  {
    lat: 30.083515002242336,
    lng: 31.246964999999932,
    location: "14 , Shiban St., beside Elkhezendara Mosque ",
    url: "https://www.google.com/maps/place/15+Mansheya+El-Bakry,+Heliopolis,+Cairo+Governorate,+Egypt/@30.0883344,31.3030846,19z/data=!4m5!3m4!1s0x14583fcdf940cdad:0x3f36f9f0363978c6!8m2!3d30.0881112!4d31.3031137",
  },
  {
    lat: 30.06806302647093,
    lng: 31.338909000000058,
    location: "16., Mohamd Hassan Elgamal from Abas Elakkad st.",
    url: "https://www.google.com/maps/place/15+Mansheya+El-Bakry,+Heliopolis,+Cairo+Governorate,+Egypt/@30.0883344,31.3030846,19z/data=!4m5!3m4!1s0x14583fcdf940cdad:0x3f36f9f0363978c6!8m2!3d30.0881112!4d31.3031137",
  },
  {
    lat: 30.132028001657236,
    lng: 31.235397000000034,
    location: "Tere'at Elsharqawia , beside Mirror market",
    url: "https://www.google.com/maps/place/15+Mansheya+El-Bakry,+Heliopolis,+Cairo+Governorate,+Egypt/@30.0883344,31.3030846,19z/data=!4m5!3m4!1s0x14583fcdf940cdad:0x3f36f9f0363978c6!8m2!3d30.0881112!4d31.3031137",
  },
  {
    lat: 30.14515502650673,
    lng: 31.397241000000008,
    location:
      "68 6october st. from Gamal abd El Naser infront of Tabarak Hospital, Geser El Suez",
    url: "https://www.google.com/maps/place/15+Mansheya+El-Bakry,+Heliopolis,+Cairo+Governorate,+Egypt/@30.0883344,31.3030846,19z/data=!4m5!3m4!1s0x14583fcdf940cdad:0x3f36f9f0363978c6!8m2!3d30.0881112!4d31.3031137",
  },
  {
    lat: 29.992305006609115,
    lng: 31.13406800000007,
    location:
      "13 Sileem st., El Hagg Naser Tower infront of El Mostakbal School",
    url: "https://www.google.com/maps/place/15+Mansheya+El-Bakry,+Heliopolis,+Cairo+Governorate,+Egypt/@30.0883344,31.3030846,19z/data=!4m5!3m4!1s0x14583fcdf940cdad:0x3f36f9f0363978c6!8m2!3d30.0881112!4d31.3031137",
  },
  {
    lat: 29.958630006600156,
    lng: 30.918552999999974,
    location: "City Star , el Mehwar El Khadamy",
    url: "https://www.google.com/maps/place/15+Mansheya+El-Bakry,+Heliopolis,+Cairo+Governorate,+Egypt/@30.0883344,31.3030846,19z/data=!4m5!3m4!1s0x14583fcdf940cdad:0x3f36f9f0363978c6!8m2!3d30.0881112!4d31.3031137",
  },
  {
    lat: 31.187822006757184,
    lng: 29.904807000000005,
    location: "47 Elshmos st ., Elbab Elgeded , MoharamBek",
    url: "https://www.google.com/maps/place/15+Mansheya+El-Bakry,+Heliopolis,+Cairo+Governorate,+Egypt/@30.0883344,31.3030846,19z/data=!4m5!3m4!1s0x14583fcdf940cdad:0x3f36f9f0363978c6!8m2!3d30.0881112!4d31.3031137",
  },
  {
    lat: 31.05053500674449,
    lng: 31.395717999999988,
    location: "6 Elsherif Alrady , Ben Hozifa , Eltorel ",
    url: "https://www.google.com/maps/place/15+Mansheya+El-Bakry,+Heliopolis,+Cairo+Governorate,+Egypt/@30.0883344,31.3030846,19z/data=!4m5!3m4!1s0x14583fcdf940cdad:0x3f36f9f0363978c6!8m2!3d30.0881112!4d31.3031137",
  },
  {
    lat: 30.80198500671385,
    lng: 31.000188999999978,
    location: "7, Elgendy St., from Omar Abd Elaziz St.",
    url: "https://www.google.com/maps/place/15+Mansheya+El-Bakry,+Heliopolis,+Cairo+Governorate,+Egypt/@30.0883344,31.3030846,19z/data=!4m5!3m4!1s0x14583fcdf940cdad:0x3f36f9f0363978c6!8m2!3d30.0881112!4d31.3031137",
  },
  {
    lat: 30.587155026732592,
    lng: 31.51032299999997,
    location: "2, Megahed Abo Eldahab St. from Elnoqrashi St",
    url: "https://www.google.com/maps/place/15+Mansheya+El-Bakry,+Heliopolis,+Cairo+Governorate,+Egypt/@30.0883344,31.3030846,19z/data=!4m5!3m4!1s0x14583fcdf940cdad:0x3f36f9f0363978c6!8m2!3d30.0881112!4d31.3031137",
  },
  {
    lat: 27.192808001550148,
    lng: 31.182530999999926,
    location: "2 El Adly st., Ferial company , Ma'mal El Otteify",
    url: "https://www.google.com/maps/place/15+Mansheya+El-Bakry,+Heliopolis,+Cairo+Governorate,+Egypt/@30.0883344,31.3030846,19z/data=!4m5!3m4!1s0x14583fcdf940cdad:0x3f36f9f0363978c6!8m2!3d30.0881112!4d31.3031137",
  },
  {
    lat: 31.09551000168616,
    lng: 29.767405000000053,
    location:
      "El Kilo 17 , Alex - Matrouh road, City Palace building no. 2 , El Hanoveel",
    url: "https://www.google.com/maps/place/15+Mansheya+El-Bakry,+Heliopolis,+Cairo+Governorate,+Egypt/@30.0883344,31.3030846,19z/data=!4m5!3m4!1s0x14583fcdf940cdad:0x3f36f9f0363978c6!8m2!3d30.0881112!4d31.3031137",
  },
]

const GoogleMaps = () => (
  <div style={{ height: "70vh", width: "100vw" }}>
    <GoogleMapReact
      bootstrapURLKeys={{ key: `AIzaSyCmJ5h-q8ltR5Kwqh1YyUi8Yg8vA5sg9yM` }}
      defaultCenter={{
        lat: 29.327201,
        lng: 31.902111,
      }}
      defaultZoom={7}
      options={mapStyle}
    >
      {branchLocations.map((item, i) => (
        <div
          key={i}
          lat={item.lat}
          lng={item.lng}
          text={item.location}
          link={item.url}
          className="googlemaps__marker"
        />
      ))}
    </GoogleMapReact>
  </div>
)

export default GoogleMaps
