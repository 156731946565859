// Import dependencies
import React from "react"

// Import components
import Layout from "../components/layouts/layout"
import SEO from "../components/seo/seo"
import Header from "../components/header/header"
import FactoryCard from "../components/contact/FactoryCard"
import ContactCard from "../components/contact/ContactCard"
import ContactForm from "../components/contact/ContactForm"
import {
  TitleText,
  PageContainer,
  Margins,
  Gap150,
  Gap60,
} from "../components/styled/UILibrary"
import TitleSection from "../components/general/titleSection"
import GoogleMaps from "../components/googlemaps/googlemaps"

// Bootstrap components
import Container from "react-bootstrap/Container"
import Row from "react-bootstrap/Row"
import Col from "react-bootstrap/Col"

// Import images
import Cairo from "../components/images/cairo"
import Sadat from "../components/images/sadat"
import Shobra from "../components/images/shobra"

// Import styles
import "./styles/contact.css"
import "./styles/history.css"
import "./styles/scrollbar.css"
import "bootstrap/dist/css/bootstrap.css"
import { Link } from "gatsby"

export default class ContactPage extends React.Component {
  componentDidMount() {
    // Hello for developers
    console.clear()
    console.log(
      "%cDesigned & developed by Dmitry Poliyivets – https://www.linkedin.com/in/dpoliyivets/",
      "background: #0000FF; color: #ffffff; font-size: 12px; padding: 25px; font-family: 'Montserrat', sans-serif; line-height: 22px"
    )
  }

  render() {
    return (
      <Layout>
        <SEO title="Contact" />
        <Header />

        <div className="history__container">
          {/* Page contents */}
          <PageContainer>
            <Margins>
              <Gap150 />

              <TitleText minSize={22} maxSize={40}>
                We are always open for cooperation
                <br />
                and looking for new promising projects
              </TitleText>

              {/* Contact Info */}
              {/* <div className='contact__info-container'>
              <TitleText minSize={22} maxSize={40}>
                (+202) 27351005
            </TitleText>
              <ParagraphText minSize={14} maxSize={14} nolineheight className='contact__info-text'>
                Spiroplastic S.A.<br />
                Cairo office telephone
            </ParagraphText>
            </div> */}

              <Gap150 style={{ marginTop: -30 }} />

              {/* Offices & Factories */}
              <Container fluid style={{ margin: 0, padding: 0 }}>
                <Row>
                  <Col xl={4} lg={6} md={6} sm={12} xs={12}>
                    <FactoryCard image={<Cairo />} title="Cairo" />
                  </Col>
                  <Col xl={4} lg={6} md={6} sm={12} xs={12}>
                    <FactoryCard image={<Sadat />} title="Sadat" />
                  </Col>
                  <Col xl={4} lg={6} md={6} sm={12} xs={12}>
                    <FactoryCard image={<Shobra />} title="Shobra" />
                  </Col>
                </Row>
              </Container>

              <Gap150 name="reach-us" />

              <TitleSection
                smallTitle="REACH US"
                title="Send us your question or partnership proposal"
              />
              <ContactForm />

              <Gap150 name="branches" />

              <TitleSection
                smallTitle="OUR BRANCHES"
                title="We have 18 shops all over Egypt"
              />
              <TitleText minSize={22} maxSize={40} gray>
                Cairo & Giza
              </TitleText>
              <Container fluid style={{ margin: 0, padding: 0 }}>
                <Row>
                  <Col xl={4} lg={6} md={6} sm={12} xs={12}>
                    <ContactCard 
                    BranchName="Abdeen" 
                    BranchManager="Atef Magdy" 
                    Address= {<a href="https://www.google.com/maps/place/1+Al+Madboli,+As+Saqayin,+Abdeen,+Cairo+Governorate,+Egypt/@30.0409827,31.2448755,19z/data=!3m1!4b1!4m13!1m7!3m6!1s0x0:0x0!2zMzDCsDAyJzI3LjEiTiAzMcKwMTQnNDQuMCJF!3b1!8m2!3d30.040868!4d31.245541!3m4!1s0x145840b754c4536f:0xae7fca4ef5c0b016!8m2!3d30.0409815!4d31.2454227" target="_blank" rel="noopener noreferrer">
                    View in Google Maps
                    </a>} 
                    Phone="01012217725 | 0223909279"
                    />
                  </Col>
                  <Col xl={4} lg={6} md={6} sm={12} xs={12}>
                    <ContactCard 
                    BranchName="Faisal" 
                    BranchManager="Hassan Saied" 
                    Address={<a href="https://www.google.com/maps/place/29%C2%B059'32.3%22N+31%C2%B008'02.6%22E/@29.9923096,31.1318793,17z/data=!3m1!4b1!4m5!3m4!1s0x0:0x0!8m2!3d29.992305!4d31.134068" target="_blank" rel="noopener noreferrer">
                    View in Google Maps
                    </a>} 
                    Phone="01012217740 | 0233835501"
                    />
                  </Col>
                  <Col xl={4} lg={6} md={6} sm={12} xs={12}>
                    <ContactCard 
                    BranchName="Gesr Elsuies" 
                    BranchManager="Zakaria Ali" 
                    Address={<a href="https://www.google.com/maps/place/Omar+Ibn+El-Khattab,+El-Bostan,+Heliopolis,+Cairo+Governorate,+Egypt/@30.0967273,31.3324964,1002m/data=!3m2!1e3!4b1!4m5!3m4!1s0x14583e1cefba3c53:0xbc208fa9efc86503!8m2!3d30.0967227!4d31.3346851" target="_blank" rel="noopener noreferrer">
                    View in Google Maps
                    </a>}  
                    Phone="01012217738 | 0226982420"
                    />
                  </Col>
                </Row>
              </Container>
              <Container fluid style={{ margin: 0, padding: 0 }}>
                <Row>
                  <Col xl={4} lg={6} md={6} sm={12} xs={12}>
                    <ContactCard 
                    BranchName="Heliopolis" 
                    BranchManager="Essam Abdelmawgod" 
                    Address={<a href="https://www.google.com/maps/place/15+Mansheya+El-Bakry,+Heliopolis,+Cairo+Governorate,+Egypt/@30.0881158,31.300925,1002m/data=!3m2!1e3!4b1!4m5!3m4!1s0x14583fcdf940cdad:0x3f36f9f0363978c6!8m2!3d30.0881112!4d31.3031137" target="_blank" rel="noopener noreferrer">
                    View in Google Maps
                    </a>} 
                    Phone="01012217704 | 0224514052"
                    />
                  </Col>
                  <Col xl={4} lg={6} md={6} sm={12} xs={12}>
                    <ContactCard 
                    BranchName="Maadi" 
                    BranchManager="Amr Mostafa" 
                    Address={<a href="https://www.google.com/maps/search/14a+Street+503+maadi/@29.9888796,31.2761476,251m/data=!3m1!1e3" target="_blank" rel="noopener noreferrer">
                    View in Google Maps
                    </a>} 
                    Phone="01005127382 | 0227336160"
                    />
                  </Col>
                  <Col xl={4} lg={6} md={6} sm={12} xs={12}>
                    <ContactCard 
                    BranchName="Nasr City" 
                    BranchManager="Hatem Saleh" 
                    Address={<a href="https://www.google.com/maps/place/16+Mohammed+Hasan+El-Gamal,+Al+Manteqah+as+Sadesah,+Nasr+City,+Cairo+Governorate,+Egypt/@30.0672869,31.3395591,1003m/data=!3m2!1e3!4b1!4m5!3m4!1s0x14583e6d5180c0d7:0x2a23131fe3f5b97c!8m2!3d30.0672823!4d31.3417478" target="_blank" rel="noopener noreferrer">
                    View in Google Maps
                    </a>}  
                    Phone="01012217734 | 0226718596"
                    />
                  </Col>
                </Row>
              </Container>
              <Container fluid style={{ margin: 0, padding: 0 }}>
                <Row>
                  <Col xl={4} lg={6} md={6} sm={12} xs={12}>
                    <ContactCard 
                    BranchName="October" 
                    BranchManager="Hesham Elhoseny" 
                    Address={<a href="https://www.google.com/maps/place/1887+Street+15,+First+6th+of+October,+Giza+Governorate,+Egypt/@29.9476123,30.9270028,502m/data=!3m2!1e3!4b1!4m13!1m7!3m6!1s0x145856730b648e9f:0x28192c167e94bddf!2sStreet+15,+First+6th+of+October,+Giza+Governorate,+Egypt!3b1!8m2!3d29.955345!4d30.9215122!3m4!1s0x14585677d0075eed:0xfd45488eb7b2ef1a!8m2!3d29.94761!4d30.9280971" target="_blank" rel="noopener noreferrer">
                    View in Google Maps
                    </a>}  
                    Phone="01069839838 | 0238244686"
                    />
                  </Col>
                  <Col xl={4} lg={6} md={6} sm={12} xs={12}>
                    <ContactCard 
                    BranchName="Haram" 
                    BranchManager="Ahmed Abdelrahman" 
                    Address={<a href="https://www.google.com/maps/place/467+Al+Haram,+Oula,+Giza+District,+Giza+Governorate,+Egypt/@30.0152958,31.1956931,8024m/data=!3m1!1e3!4m5!3m4!1s0x145846e104de61e7:0xc14cad142a87ef59!8m2!3d30.0152912!4d31.2132026" target="_blank" rel="noopener noreferrer">
                    View in Google Maps
                    </a>}  
                    Phone="01012217729 | 0235690594"
                    />
                  </Col>
                  <Col xl={4} lg={6} md={6} sm={12} xs={12}>
                    <ContactCard 
                    BranchName="Sharkawyah" 
                    BranchManager="Hussein Ateek" 
                    Address={<a href="https://www.google.com/maps/place/20+Al+Masaken,+Masaken+Al+Amireyah+Al+Ganoubeyah,+Hada'iq+El+Qobbah,+Cairo+Governorate,+Egypt/@30.0972013,31.2768225,1002m/data=!3m2!1e3!4b1!4m8!1m2!2m1!1s19+St.+No.+20+masaken+ElSharkawiya++Shubra+El-Kheima!3m4!1s0x14583fe5cd692de1:0xf8e8467edd905a68!8m2!3d30.0971967!4d31.2790112" target="_blank" rel="noopener noreferrer">
                    View in Google Maps
                    </a>}  
                    Phone="01012217736 | 0244445590"
                    />
                  </Col>
                </Row>
              </Container>
              <Container fluid style={{ margin: 0, padding: 0 }}>
                <Row>
                  <Col xl={4} lg={6} md={6} sm={12} xs={12}>
                    <ContactCard 
                    BranchName="Shubra" 
                    BranchManager="Mohamed Elsayed" 
                    Address={<a href="https://www.google.com/maps/place/Al+Khazendarah,+Elsahel,+Cairo+Governorate,+Egypt/@30.0823731,31.2538649,19z/data=!4m5!3m4!1s0x145840722e06d68f:0xbcb456543d7f0390!8m2!3d30.0848624!4d31.2547677" target="_blank" rel="noopener noreferrer">
                    View in Google Maps
                    </a>}  
                    Phone="01012217728 | 0222041523"
                    />
                  </Col>
                  <Col xl={4} lg={6} md={6} sm={12} xs={12}>
                    <ContactCard 
                    BranchName="Imbaba" 
                    BranchManager="Atef Abdelmeseh" 
                    Address={<a href="https://www.google.com/maps/place/Street+28,+Al+Munirah,+Imbaba,+Giza+Governorate,+Egypt/@30.0872263,31.2072297,17z/data=!3m1!4b1!4m8!1m2!2m1!1s65+Rd.+28+imbaba!3m4!1s0x145841aecba42c67:0xd6f13c30128d09ce!8m2!3d30.0872216!4d31.2094184" target="_blank" rel="noopener noreferrer">
                    View in Google Maps
                    </a>}  
                    Phone="01012217721 | 0233160531"
                    />
                  </Col>
                </Row>
              </Container>

              <Gap60 name="branches" />

              <TitleText minSize={22} maxSize={40} gray>
                Alexandria
              </TitleText>

              <Container fluid style={{ margin: 0, padding: 0 }}>
                <Row>
                  <Col xl={4} lg={6} md={6} sm={12} xs={12}>
                    <ContactCard 
                    BranchName="Agamy" 
                    BranchManager="Adel Rasmy" 
                    Address={<a href="https://www.google.com/maps/search/2%D8%B9%D9%85%D8%A7%D8%B1%D8%A7%D8%AA+%D8%B3%D9%8A%D8%AA%D9%89+%D8%A8%D8%A7%D9%84%D8%A7%D8%B3+%D8%A7%D9%84%D9%87%D8%A7%D9%86%D9%88%D9%81%D9%8A%D9%84-%D8%A7%D9%84%D8%B9%D8%AC%D9%85%D9%89%E2%80%AD/@31.1057545,29.7626889,17z/data=!3m1!4b1" target="_blank" rel="noopener noreferrer">
                    View in Google Maps
                    </a>}  
                    Phone="01012217746 | 033015551"
                    />
                  </Col>
                  <Col xl={4} lg={6} md={6} sm={12} xs={12}>
                    <ContactCard 
                    BranchName="Alexandria" 
                    BranchManager="Mohamed Gamal" 
                    Address={<a href="https://www.google.com/maps/place/31%C2%B011'16.2%22N+29%C2%B054'17.3%22E/@31.1878266,29.9026183,17z/data=!3m1!4b1!4m5!3m4!1s0x0:0x0!8m2!3d31.187822!4d29.904807" target="_blank" rel="noopener noreferrer">
                    View in Google Maps
                    </a>}  
                    Phone="01012217745 | 034940873"
                    />
                  </Col>
                </Row>
              </Container>

              <Gap60 name="branches" />
              
              <TitleText minSize={22} maxSize={40} gray>
                Assiut Governorate
              </TitleText>
              
              <Container fluid style={{ margin: 0, padding: 0 }}>
                <Row>
                  <Col xl={4} lg={6} md={6} sm={12} xs={12}>
                    <ContactCard 
                    BranchName="Asyut" 
                    BranchManager="Ahmed Nasr" 
                    Address={<a href="https://www.google.com/maps/search/2+El+Adly+St.,+with+Abdel+Rahman+Street+-+Ferial,++Assiut/@27.1924268,31.1798858,17z/data=!3m1!4b1" target="_blank" rel="noopener noreferrer">
                    View in Google Maps
                    </a>} 
                    Phone="01012217749 | 0882057257"
                    />
                  </Col>
                </Row>
              </Container>

              <Gap60 name="branches" />

              <TitleText minSize={22} maxSize={40} gray>
                Mansoura
              </TitleText>

              <Container fluid style={{ margin: 0, padding: 0 }}>
                <Row>
                  <Col xl={4} lg={6} md={6} sm={12} xs={12}>
                    <ContactCard 
                    BranchName="Mansoura" 
                    BranchManager="Mostafa Abdelrahman" 
                    Address={<a href="https://www.google.com/maps/place/27%C2%B011'34.1%22N+31%C2%B010'57.1%22E/@27.1928128,31.1475121,13z/data=!4m5!3m4!1s0x0:0x0!8m2!3d27.192808!4d31.182531" target="_blank" rel="noopener noreferrer">
                    View in Google Maps
                    </a>}  
                    Phone="01012217747 | 0502100963"
                    />
                  </Col>
                </Row>
              </Container>

              <Gap60 name="branches" />

              <TitleText minSize={22} maxSize={40} gray>
                Tanta
              </TitleText>

              <Container fluid style={{ margin: 0, padding: 0 }}>
                <Row>
                  <Col xl={4} lg={6} md={6} sm={12} xs={12}>
                    <ContactCard 
                    BranchName="Tanta" 
                    BranchManager="Naser Naguieb" 
                    Address={<a href="https://www.google.com/maps/place/El-Gendy+Mosque,+Tanta+Qism+2,+Tanta,+Gharbia+Governorate,+Egypt/@30.8023251,30.9979908,17z/data=!3m1!4b1!4m13!1m7!3m6!1s0x14f7c95daafcf035:0x7421820c5e8cae42!2sTanta,+Tanta+Qism+2,+Tanta,+Gharbia+Governorate,+Egypt!3b1!8m2!3d30.7865086!4d31.0003757!3m4!1s0x14f7c99d4439bd2d:0x6702f7a9456d3757!8m2!3d30.8023204!4d31.000178" target="_blank" rel="noopener noreferrer">
                    View in Google Maps
                    </a>}  
                    Phone="01012217751 | 0403270668"
                    />
                  </Col>
                </Row>
              </Container>

              <Gap60 name="branches" />

              <TitleText minSize={22} maxSize={40} gray>
                Damietta
              </TitleText>

              <Container fluid style={{ margin: 0, padding: 0 }}>
                <Row>
                  <Col xl={4} lg={6} md={6} sm={12} xs={12}>
                    <ContactCard 
                    BranchName="Damietta" 
                    BranchManager="Salama Goda" 
                    Address={<a href="https://www.google.com/maps/search/%D8%B4%D8%A7%D8%B1%D8%B9+%D8%AA%D9%82%D8%B3%D9%8A%D9%85+%D8%A7%D9%84%D9%85%D8%B9%D9%84%D9%85%D9%8A%D9%86+%D8%A7%D9%85%D8%A7%D9%85+%D9%82%D8%B3%D9%85+%D8%AB%D8%A7%D9%86+%D8%AF%D9%85%D9%8A%D8%A7%D8%B7%E2%80%AD/@31.4176288,31.7697877,13z/data=!3m1!4b1" target="_blank" rel="noopener noreferrer">
                    View in Google Maps
                    </a>}  
                    Phone="01007722288 | 0572198488"
                    />
                  </Col>
                </Row>
              </Container>

              <Gap60 name="branches" />

              <TitleText minSize={22} maxSize={40} gray>
                Zagazig
              </TitleText>

              <Container fluid style={{ margin: 0, padding: 0 }}>
                <Row>
                  <Col xl={4} lg={6} md={6} sm={12} xs={12}>
                    <ContactCard 
                    BranchName="Zagazig" 
                    BranchManager="Ahmed Abdella" 
                    Address={<a href="https://www.google.com/maps/place/30%C2%B035'13.8%22N+31%C2%B030'37.2%22E/@30.5871596,31.4753041,13z/data=!4m5!3m4!1s0x0:0x0!8m2!3d30.587155!4d31.510323" target="_blank" rel="noopener noreferrer">
                    View in Google Maps
                    </a>}  
                    Phone="01012217756 | 0552264687"
                    />
                  </Col>
                </Row>
              </Container>

              <Gap150 name="branches" />

            </Margins>
          </PageContainer>

          {/* Google maps */}
          <GoogleMaps />
        </div>
      </Layout>
    )
  }
}
